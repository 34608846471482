<template lang="pug">
.tree
  .list(
    v-for="(item, parentName) in items"
    :key="parentName"
  )
    .parrent(v-if="currentParrents.hasOwnProperty(parentName)")
      input(
        type="checkbox"
        :id="`parent_${parentName}`"
        :class="parrentClass(parentName)"
        :checked="Boolean(currentParrents[parentName])"
        :disabled="isLimitFull"
        @change="use($event, parentName)"
      )
      label(
        :for="`parent_${parentName}`"
        :class="childClass(parentName)"
      ) 
        span.square-bg
        | {{ parentName }}
      i.symbol(@click="toggleChildren(parentName)") {{ changeSymbol(parentName) }}

    template(v-if="currentParrents.hasOwnProperty(parentName)")
      .child(v-if="multiTree")
        MultiCheckboxesTree(
          v-show="checkEnabled(parentName)"
          class="child-item tree-child-item"
          :items="currentTreeItems(item)"
          :limit="limit"
          :checkedStores="checkedStores"
          :isMainPage="isMainPage"
          @select-checkbox="$emit('select-item')"
        )
      template(v-else)
        .child(
          v-for="i in item"
          :key="i.id"
        )
          .child-item(v-show="checkEnabled(parentName)")
            input(
              type="checkbox"
              class="custom-checkbox"
              :id="`${parentName}-${i.id + i.parentId}`"
              :parrent-name="parentName"
              :disabled="limit && !i.checked && isLimitFull && !isMainPage"
              v-model="i.checked"
              @change="change($event, parentName)"
            )
            label.mt-2(
              :for="`${parentName}-${i.id + i.parentId}`"
              :class="childClass(i)"
            ) 
              span.square-bg
              | {{ i.name }}
</template>

<script>
import MultiCheckboxesTree from '@/components/Elements/MultiCheckboxesTree.vue';
export default {
  name: 'MultiCheckboxesTree',
  components: {
      MultiCheckboxesTree,
  },
  props: {
    items: {
      type: [Object, Array],
      required: true,
    },
    multiTree: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null,
    },
    checkedStores: {
        type: Number,
        default: 0,
    },
    isMainPage: {
        type: Boolean,
        default: false
    },
  },
  data() {
    return {
      parrent: {},
      child: {},
      enabledChildren: {},
    }
  },
  computed: {
    currentParrents() {
      if (!this.items || !Object.keys(this.items).length) return {};
      
      const parentClasses = {};
      const itemsEntries = Object.entries(this.items);

      itemsEntries.forEach(([key, value]) => {
        const checkedValuesLength = value.filter((item) => item.checked).length;

        parentClasses[key] = checkedValuesLength === value.length
          ? true
          : checkedValuesLength === 0
          ? false
          : 'not-complete'
      })

      return parentClasses;
    },
    isLimitFull() {
      return this.limit <= this.checkedStores && !this.isMainPage;
    },
  },
  methods: {
    currentTreeItems(groupTreeItems) {
            const groupsNames = groupTreeItems.map((item) => item.parentName);
            const groupsBranch = Array.from(new Set(groupsNames));

            const treeItems = {};

            groupsBranch.forEach((parentName) => {
                treeItems[parentName] = groupTreeItems.filter((item) => item.parentName === parentName);
            })

            return treeItems;
    },

    checkEnabled(parentName) {
      return Object.prototype.hasOwnProperty.call(this.enabledChildren, parentName) && this.enabledChildren[parentName]
    },

    isChecked(value){
      return value.checked ? value.checked : false
    },

    parrentClass(key) {
      return {
        'custom-checkbox-parrent' : this.currentParrents[key] == 'not-complete',
        'custom-checkbox' :  this.currentParrents[key] != 'not-complete',
      }
    },

    childClass(key) {
      return {
        'checked': this.isChecked(key) || this.currentParrents[key]
      }
    },

    use(e, key) {
      const checked = e.target.checked
      const isLinmit = this.isLimitFull && checked
      let checkedCount = this.checkedStores;

      if (isLinmit) return;

      this.items[key].forEach(el => {
        if (this.isLimitFull || (!this.isMainPage && this.limit <= checkedCount)) return;
        checked ? checkedCount++ : checkedCount--;
        this.$set(el, 'checked', checked)
      })
      this.$emit('select-checkbox', key)
    },

    change(event, key) {
      this.$emit('select-checkbox', key)

      const name = event.target.getAttribute('parrent-name')
      const count = this.items[name].reduce((acc, el) => {
        if(el.checked === true) {
          return acc += 1
        } else {
          return acc
        }
      }, 0)

      if(count) {
        this.parrent[name] = count === this.items[name].length ? true : 'not-complete'
      } else {
        this.parrent[name] = count === 0 ? false : 'not-complete'
      }
    },

    toggleChildren(id) {
      if (Object.prototype.hasOwnProperty.call(this.enabledChildren, id)) {
        this.enabledChildren[id] = !this.enabledChildren[id]
      } else {
        this.$set(this.enabledChildren, id, true)
      }
    },

    changeSymbol(id) {
      return this.enabledChildren[id] ? '-' : '+'
    },

  },

  watch: {
    'items': {
      deep: true,
      handler(value) {
        Object.entries(this.items).forEach(([key, values]) => {
          const amountChecked = values.reduce((acc, current) => {
            if(current.checked) {
              acc+= current.checked
            }
            return acc
          }, 0)
          this.parrent[key] = amountChecked === values.length ? true : amountChecked === 0 ? false : 'not-complete'
        })

        if(!Object.keys(value).length) {
          this.parrent = {}
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.checked {
  background-color: rgba(87, 68, 214, 0.05);
  &::before {
    border: 2px solid color(violet) !important;
  }
}

.child {
  display: flex;
  padding-left: 24px;
  &-item {
    width: 100%;
  }
}

.parrent {
  display: flex;
  flex-direction: column;
  position: relative;
}

.symbol {
  font-style: normal;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  color: gray;
  cursor: pointer;
  display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
  transition: .3s all ease;
  &:hover {
    color: #5744D6;
    cursor: pointer;
  }
}

.custom-checkbox, .custom-checkbox-parrent {
  display: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label, .custom-checkbox-parrent+label {
  display: flex;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  width: 100%;
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 32px;
  padding-bottom: 12px;
  display: flex;
  align-items: start;
  &:hover {
    cursor: pointer;
    background-color: rgba(87, 68, 214, 0.05);
    &::before {
      border: 2px solid color(violet);
    }
  }
}

.custom-checkbox:disabled+label:hover::before {
  border: 2px solid #BDBDBD;
}

.custom-checkbox+label::before, .custom-checkbox-parrent+label::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #BDBDBD;
  border-radius: 4px;
  color: #212121;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  margin: 0 8px 0 0;
}
.custom-checkbox:checked+label::before, .custom-checkbox-parrent:checked+label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 12px;
  border: 2px solid #BDBDBD;
  border-radius: 4px;
  color: #212121;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  position: relative;
}
.custom-checkbox:checked+label::after {
  font-family: "Material Icons";
  content: "check";
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 18px;
  color: #5744D6 !important;
}

.custom-checkbox-parrent:checked+label::after {
  content: "-";
  position: absolute;
  left: 19px;
  top: 10px;
  font-size: 34px;
  color: #212121;
}

.checked .square-bg,
label:hover .square-bg {
  display: block;
  background: rgba(87, 68, 214, 0.05);
  width: 48px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -48px;
}

.tree-child-item .parrent{
  margin-bottom: 1px;
  &:before {
    content: '';
    width: calc(100% + 24px);
    height: 1px;
    background-color: #9E9E9E;
    position: absolute;
    left: -24px;
  }
}

.custom-checkbox:disabled + label {
  opacity: 0.5;
  cursor: default;
}
</style>
